import React from 'react';
import { FlexboxGrid, Col, Button } from 'rsuite';

import Layout from 'containers/Layout';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';

const H1 = styled('h1')`
  text-transform: uppercase;
`;

const H4 = styled('h4')`
  margin-bottom: 30px;
  font-weight: 400;
`;

function Custom404() {
  const router = useRouter();

  return (
    <Layout type={'generic'} byPassAuth={true}>
      <FlexboxGrid
        className="animated bounce"
        style={{ height: '100%', textAlign: 'center', marginTop: '10%' }}
        align="middle"
        justify="space-around">
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={6}>
          <H1>Sorry</H1>
          <H4>Page Not Found</H4>
          <p>Either something is wrong or this page does not exist anymore </p>
          <br></br>
          <Button onClick={() => router.back()} appearance="link">
            Go Back
          </Button>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Layout>
  );
}

// Declare namespacesRequired for the Custom404 page
Custom404.namespacesRequired = ['common']; // Adjust according to your namespaces

export default Custom404;
